import React, { useEffect, useState } from "react";
import { MarkerInfo } from "../models/interfaces/MarkerInfo";
import { useLocation, useParams } from "react-router-dom";
import {
  fetchInitialSearchResults,
  fetchNextPage,
  fetchSearchResults,
  setSortByOption,
  UseItemSearchParams,
} from "../redux/searchSlice";

import { ItemOrderBy, ItemSearchDto } from "../api/rentMyApi";
import { Button, Col, Row } from "reactstrap";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMap } from "@fortawesome/free-solid-svg-icons";
import { sendSearchEvent, viewItemListAnalytics } from "../api/analytics";
import { Widget } from "@typeform/embed-react";
import { useAuth0 } from "@auth0/auth0-react";
import { useUserContext } from "../context/UserContext";
import { SmallLoadingSpinner } from "../components/common/Loaders";
import ItemHighlight from "../components/item/ItemHighlight"
import GoogleMapsBounds from "../components/maps/GoogleMapsBounds";
import BackButton from "../components/common/BackButton";
import TopLevelCategories from "../components/search/TopLevelCategories";
import Select, { SingleValue, StylesConfig } from "react-select";
import GoogleMapsSingle from "../components/maps/GoogleMapsSingle";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../redux/store";
import {parsePath} from "../utils/url";

export type OptionType = {
  value: ItemOrderBy;
  label: string;
};


export function SearchResultsPage() {
  const { t } = useTranslation();
  const [loadedSearchParams, setLoadedSearchParams] = useState<boolean>(false);
  const [coordinates, setCoordinates] = useState<{ lat: number | undefined; lng: number | undefined, default: boolean | undefined }>({
    lat:  undefined,
    lng:  undefined,
    default: false
  })
  // const { what, where }: any = useParams();
  // const [whereName, setWhereName] = useState<string | undefined>(where);
  // const [whatName, setWhatName] = useState<string | undefined>(what);
  const searchParams = new URLSearchParams(window.location.search);
  const { user } = useUserContext();

  const location = useLocation();
  const { pathname } = useLocation();
  const {what, where} = parsePath(pathname);
  const [itemSelectedFocusedId, setItemSelectedFocusedId] = useState<
      null | string
  >(null);
  const [itemHighlightsMarkers, setItemHighlightsMarkers] = useState<
      MarkerInfo[]
  >([]);
  const [showMapMobile, setShowMapMobile] = useState<boolean>(false);
  const zoomLevelIfJustOneItem: number = 17;
  const screenWidth = window.innerWidth;
  const isMobile = screenWidth <= 768;

  const { isAuthenticated, isLoading: isLoadingAuth } = useAuth0();

  const {
    itemSearchPaginated,
    isLoading,
    sortByOption,
    topLevelCategories,
    isLoadingNextPage,
    isItemsLoading,
    searchTerm,
    selectedPlace,
    selectedCategoryId,
  } = useSelector((state: RootState) => state.search);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    const getCoordinates = async () => {
      let rawLat = searchParams.get("lat");
      let rawLng = searchParams.get("lng");

      // DEV NOTE: This code below will use the user's location if the user is authenticated and has a location set as the search location if no search location is provided

      if (rawLat === null || rawLng === null) {
        try {
          // console.log("Geocoding address")
          // console.log("Where: " + where)
          // console.log("User: ")
          // console.log(user)
          const geocoder = new google.maps.Geocoder();
          // let address = where ? where : ( isAuthenticated && user && user.id && user.location ? (user.location.addressLine1 + ", " + user.location.city): undefined )
          let address = where ? where : ""

          // if address doesn't end in ' uk' or ' United Kingdom' then append ' uk'
          if (address && !address.toLowerCase().endsWith(' uk') && !address.toLowerCase().endsWith(' united kingdom')) {
            address += ', United Kingdom';
          }
          console.log("address", address)

          if (address) {
            const { results } = await geocoder.geocode({ address: address });
            if (results && results.length > 0) {
              const result = results[0];
              rawLat = result.geometry.location.lat().toString();
              rawLng = result.geometry.location.lng().toString();
            }
          }


        } catch (error) {
            console.log('Error geocoding address:', error);
        }

      }
      // Attempt to parse the latitude value
      const parsedLat = parseFloat(rawLat!);
      const parsedLng = parseFloat(rawLng!);
      // If parsedLat is NaN, set searchParamsLat to undefined, otherwise use parsedLat
      const searchParamsLat: number | undefined = isNaN(parsedLat)
          ? undefined
          : parsedLat;
      const searchParamsLng: number | undefined = isNaN(parsedLng)
          ? undefined
          : parsedLng;
      if (searchParamsLat && searchParamsLng) {
        setCoordinates({
          lat: searchParamsLat,
          lng: searchParamsLng,
            default: false
        })
      } else {
      //   54.380154563847306, -2.445868839842388
      //   center of UK
        setCoordinates({
          lat: 54.380154563847306,
          lng: -2.445868839842388,
          default: true
        })
      }
    }
    // if (isAuthenticated && !user.id) return
    getCoordinates().then((coords) => {
        setLoadedSearchParams(true);
    })
  }, [isAuthenticated, isLoadingAuth, user.id])

  const sortByOptions: { value: ItemOrderBy; label: string }[] = [
    { value: ItemOrderBy.Relevance, label: t("search_results_sort_relevance") },
    {
      value: ItemOrderBy.DailyPrice,
      label: t("search_results_sort_daily_price"),
    },
    { value: ItemOrderBy.Distance, label: t("search_results_sort_distance") },
    { value: ItemOrderBy.Rating, label: t("search_results_sort_rating") },
    { value: ItemOrderBy.Newest, label: t("search_results_sort_newest") },
  ];



  const formatSearchParams = (
    query: string,
    pageNumber: number,
    lat: number | undefined,
    lng: number | undefined
  ): UseItemSearchParams => {
    return {
      searchQuery: query,
      pageNumber: pageNumber,
      sortByOption:
        sortByOption === undefined ? sortByOption : sortByOption.value,
      categoryId: selectedCategoryId,
      pageSize: itemSearchPaginated.pageSize,
      lat: lat,
      lng: lng,
    };
  };

  // Initialise any default variables
  useEffect(() => {
    dispatch(
      setSortByOption({
        value: ItemOrderBy.Relevance,
        label: t("search_results_sort_relevance"),
      })
    );
  }, [t]);

  async function onLoadMoreItems() {
    if (
        (itemSearchPaginated.data?.length ?? 0) >=
        itemSearchPaginated.totalDataCount ||
        isLoadingNextPage
    ) {
      return;
    }
    const newSearchParams = formatSearchParams(
        searchTerm ?? "",
        itemSearchPaginated.page + 1,
        where ? coordinates.lat ?? undefined : undefined,
        where ? coordinates.lng ?? undefined : undefined
    );
    dispatch(fetchNextPage(newSearchParams));
  }

  const handleScroll = async (e: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (
      scrollHeight - scrollTop <= clientHeight * 1.05 &&
      !isItemsLoading &&
      !isLoadingNextPage
    ) {
      // Adjust the multiplier as necessary
      await onLoadMoreItems();
    }
  };

  useEffect(() => {
    if (!loadedSearchParams) return;
    let query = searchTerm;
    const pathSegments = location.pathname.split("/");
    if (pathSegments.length > 2) query = pathSegments[2].replaceAll("%20", " ");
    const searchParams: UseItemSearchParams = formatSearchParams(
      query,
      1,
      where ? coordinates.lat ?? undefined : undefined,
      where ? coordinates.lng ?? undefined : undefined
    );
    dispatch(fetchInitialSearchResults(searchParams));
  }, [coordinates.lat, coordinates.lng, loadedSearchParams]);

  // on search term change or filter change
  useEffect(() => {
    if (isLoading) return;
    const searchParams = formatSearchParams(
      searchTerm,
      itemSearchPaginated.page,
        where ? coordinates.lat ?? undefined : undefined,
        where ? coordinates.lng ?? undefined : undefined
    );
    console.log(searchParams);
    dispatch(fetchSearchResults(searchParams));
  }, [
    searchTerm,
    selectedCategoryId,
    sortByOption,
    selectedPlace,
    coordinates.lat,
    coordinates.lng
  ]);

  // Meta Conversions API
  useEffect(() => {
    if (isAuthenticated && user.id && what) {
      const accountID = user.id;
      const locationSearched = where;
      const userLocation = user.location ?? "N/A";
      const isMobile = /Mobile/i.test(navigator.userAgent);
      const device = isMobile ? "Mobile" : "Desktop";
      sendSearchEvent(
        what,
        locationSearched,
        accountID,
        userLocation,
        device
      );
      viewItemListAnalytics({ searchParams }); //old pixel
    }
  }, [isAuthenticated, user, what]);

  // const getMeanLocation = (markers: MarkerInfo[]) => {
  //   if (markers.length === 0) return;
  //
  //   let totalLat = 0;
  //   let totalLng = 0;
  //
  //   for (const marker of markers) {
  //     totalLat += marker.latitude;
  //     totalLng += marker.longitude;
  //   }
  //
  //
  //   setCoordinates({
  //     lat: totalLat / markers.length,
  //     lng: totalLng / markers.length
  // })}

  const getMedianLocation = (markers: MarkerInfo[]) => {
    if (markers.length === 0) return;

    // Sort markers by latitude
    const sortedMarkersByLat = markers.sort((a, b) => a.latitude - b.latitude);
    // Sort markers by longitude

    const midIndex = Math.floor(markers.length / 2);

    // Select the marker at the median index
    const medianMarker = sortedMarkersByLat[midIndex];

    // If the number of markers is odd, return the middle element
    // If even, return the average of the two middle elements
    const medianLat = medianMarker.latitude;

    const medianLng = medianMarker.longitude

    setCoordinates({
      lat: medianLat,
      lng: medianLng,
        default: false
    });
  }



  useEffect(() => {
    generateMarkersForItemHighlights(itemSearchPaginated.data ?? []);
  }, [itemSearchPaginated.data]);

    useEffect(() => {
      if (!coordinates.lat || !coordinates.lng) {
        getMedianLocation(itemHighlightsMarkers);
      }
  }, [itemHighlightsMarkers, coordinates, itemSearchPaginated.data]);

  function generateMarkersForItemHighlights(data: ItemSearchDto[]) {
    const markerInfo: MarkerInfo[] = [];

    for (const item of data) {
      if (item.latitude && item.longitude) {
        markerInfo.push({
          latitude: item.latitude,
          longitude: item.longitude,
          idOfAttachedItem: item.id,
        });
      }
    }
    setItemHighlightsMarkers(markerInfo);
  }

  function handleMarkerClick(id: string) {
    setShowMapMobile(false);
    setItemSelectedFocusedId(id);
    const element = document.getElementById("item_" + id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }

  const colourStyles: StylesConfig = {
    control: (styles, {}) => ({ ...styles, backgroundColor: 'white', boxShadow: '#ea554a', '&:hover': {
        borderColor: '#ea554a' } }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const color = "#ffff";
      return {
        ...styles,
        borderColor: isFocused ? "#ea554a" : undefined,
        backgroundColor: isDisabled
            ? undefined
            : isSelected
                ? "#ea554a"
                : isFocused
                    ? "#f8ece9"
                    : undefined,
        cursor: isDisabled ? 'not-allowed' : 'default',

        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled
              ? isSelected
                  ? "#ea554a"
                  : undefined
              : undefined,
        },
      };
    }
    };


  const handleChange = (selectedOption: SingleValue<OptionType>) => {
    const newOption: { value: ItemOrderBy; label: string } = {
      value: selectedOption!.value,
      label: selectedOption!.label,
    };
    dispatch(setSortByOption(newOption));
  };

  if (isLoading || isLoadingAuth || !loadedSearchParams)
    return (
      <div className="spinner-container">
        <SmallLoadingSpinner />
      </div>
    );

  return (
    <>
      <Col xs={12}>
        {!isMobile && topLevelCategories.length !== 0 && (
            <Row className="top-level-categories" style={{ display: 'flex', alignItems: 'center' }}>
              <Col xs="auto" style={{ flexGrow: 1, maxWidth: 'calc(100% - 190px)', backgroundColor: "white", paddingRight: 0 }}>
                <TopLevelCategories />
              </Col>
              <Col xs="auto" style={{ minWidth: '190px' }}>
                {!isMobile && (
                    <Row className="filter-component">
                      <Col xs={12} md={12}>
              <span className="small">
                {t("search_results_sort_label")}
              </span>
                        <Select
                            defaultValue={sortByOption}
                            options={sortByOptions}
                            menuPlacement="auto"
                            menuPosition="fixed"
                            onChange={(selectedOption) => handleChange(selectedOption as SingleValue<OptionType>)}
                            styles={colourStyles}
                        />
                      </Col>
                    </Row>
                )}
              </Col>
            </Row>
        )}
          {itemSearchPaginated.totalDataCount < 1 ? (
              <Row className="spinner-container">
                  <Widget
                      id={process.env.REACT_APP_TYPEFORM_ID || ""}
                      className="typeform-container"
                  />
              </Row>
          ) : (
              <Row>
                  <Col
                      xs={12}
                      md={itemHighlightsMarkers.length > 0 ? 7 : 12}
              className="items-section"
              onScroll={handleScroll}
            >
              <Row>
                <h4 className="m-auto pb-3 pt-3">
                  {/*{`Showing ${itemSearchPaginated.totalDataCount} results ${(whatName && whatName !== "all") ? ("for " + "'" + whatName + "'") : ""} ${(where && false) ? `in '${where}'` : ""}`}</h4>*/}
                  {`Showing all results ${
                    what && what !== "all"
                      ? "for " + "'" + what + "'"
                      : ""
                  }`}
                </h4>
              </Row>
              <Row className="search-results">
                {isItemsLoading ? (
                  <SmallLoadingSpinner />
                ) : (
                  itemSearchPaginated.data?.map(
                    (itemHighlight, index: number) => {
                      const isFocused =
                        itemHighlight.id == itemSelectedFocusedId;
                      return (
                        <Col
                          id={`item_${itemHighlight.id}`}
                          key={itemHighlight.id}
                          xs={12}
                          sm={12}
                          md={4}
                          lg={4}
                          xl={4}
                        >
                          <ItemHighlight
                            item={itemHighlight}
                            isFocused={isFocused}
                            isItemSearch={true}
                          />
                        </Col>
                      );
                    }
                  )
                )}
              </Row>
              <div className="d-flex flex-row justify-content-center align-items-center my-3 col-sm-12">
                {isLoadingNextPage && <SmallLoadingSpinner />}
              </div>
            </Col>
                {itemHighlightsMarkers.length > 0 && <Col
              xs={12}
              md={5}
              className={`map-search-results ${
                showMapMobile ? "map-visible" : ""
              }`}
            >
              {itemHighlightsMarkers.length > 1 && (
                <GoogleMapsBounds
                  itemSelectedFocusedId={itemSelectedFocusedId}
                  onMarkerClick={handleMarkerClick}
                  markers={itemHighlightsMarkers}
                  latitude={coordinates.lat}
                  longitude={coordinates.lng}
                  defaultZoom={coordinates.default}
                  mapOpen={showMapMobile}
                />
              )}
              {itemHighlightsMarkers.length === 1 && (
                <GoogleMapsSingle
                  lat={itemHighlightsMarkers[0].latitude}
                  lng={itemHighlightsMarkers[0].longitude}
                  wantMarker={true}
                  zoom={zoomLevelIfJustOneItem}
                />
              )}
            </Col>}
          </Row>
        )}
      </Col>

      {showMapMobile &&
        itemSelectedFocusedId !== null &&
        itemSearchPaginated &&
        itemSearchPaginated.data &&
        itemSearchPaginated.data[itemSelectedFocusedId as any] && (
          <div className="map_search_selected_item">
            <ItemHighlight
              item={itemSearchPaginated.data[itemSelectedFocusedId as any]}
              isFocused={false}
              isItemSearch={true}
            />
          </div>
        )}

      {showMapMobile && (
        <div className="map-button-close">
          <BackButton onClick={() => setShowMapMobile(false)} />
        </div>
      )}
      {/*Map hidden for now due to bug which doesn't show markers initially*/}
      {/*{itemSearchPaginated &&*/}
      {/*  itemSearchPaginated.data &&*/}
      {/*  itemSearchPaginated.data?.length > 0 && (*/}
      {/*    <Button*/}
      {/*      color="primary"*/}
      {/*      className="map-button-search"*/}
      {/*      onClick={() => setShowMapMobile(!showMapMobile)}*/}
      {/*    >*/}
      {/*      <FontAwesomeIcon className="mr-2" icon={faMap} />*/}
      {/*      {showMapMobile ? "Hide Map" : t("map")}*/}
      {/*    </Button>*/}
      {/*  )}*/}
    </>
  );
}
